/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types, applySnapshot } from 'mobx-state-tree';

// 'COMPLETED', 'PENDING', 'TAINTED', 'FAILED', 'TERMINATING', 'TERMINATED', 'TERMINATING_FAILED', 'INSUFFICIENT_FUNDS', 'EXPIRED', 'UNKNOWN'
// Note: 'UNKNOWN' is not something that is returned from the server, it is here to catch any other status
// that we don't know about.
const states = [
  {
    key: 'COMPLETED',
    display: 'RUNNING',
    color: 'green',
    tip: 'The workspace is ready to be used.',
    spinner: false,
    canTerminate: true,
    canConnect: true,
    canStop: true,
    canStart: false,
  },
  {
    key: 'PENDING',
    display: 'PROVISIONING..',
    color: 'yellow',
    tip: 'The workspace is being built, this will take between 5 and 15 minutes.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'STOPPED',
    display: 'STOPPED',
    color: 'orange',
    tip: 'The workspace is stopped. You will only be charged for the storage space',
    spinner: false,
    canTerminate: true,
    canConnect: false,
    canStop: false,
    canStart: true,
  },
  {
    key: 'STOPPING',
    display: 'STOPPING..',
    color: 'orange',
    tip: 'The workspace is stopping.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'STOPPING_FAILED',
    display: 'STOP FAILED',
    color: 'red',
    tip: 'The workspace failed to stop. The system is attempting to determine what happened, and will fix this status automatically.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'STARTING',
    display: 'STARTING..',
    color: 'orange',
    tip: 'The workspace is starting.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'STARTING_FAILED',
    display: 'START FAILED',
    color: 'red',
    tip: 'The workspace failed to start. The system is attempting to determine what happened, and will fix this status automatically.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'TAINTED',
    display: 'TAINTED',
    color: 'orange',
    tip: 'The workspace is ready but the latest configuration updates might not have been successful.',
    spinner: false,
    canTerminate: true,
    canConnect: true,
    canStop: false,
    canStart: false,
  },
  {
    key: 'FAILED',
    display: 'FAILED',
    color: 'red',
    tip: 'Something went wrong. Feel free to try again or contact the RACE team for support.',
    spinner: false,
    canTerminate: true,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'TERMINATING',
    display: 'DELETING..',
    color: 'red',
    tip: 'The workspace is being terminated. This could take sometime.',
    spinner: true,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'TERMINATED',
    display: 'DELETED',
    color: 'grey',
    tip: 'The workspace was deleted successfully and no longer available.',
    spinner: false,
    canTerminate: false,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'TERMINATING_FAILED',
    display: 'DELETE FAILED',
    color: 'red',
    tip:
      'The workspace was not terminated correctly, it is possible that some compute and storage resources are still in place.',
    spinner: false,
    canTerminate: true,
    canConnect: true,
    canStop: false,
    canStart: false,
  },
  {
    key: 'INSUFFICIENT_FUNDS',
    display: 'NOT ENOUGH CREDIT',
    color: 'purple',
    tip: 'The workspace was not started due to insufficient credit. Contact RACE for more information.',
    spinner: false,
    canTerminate: true,
    canConnect: false,
    canStop: false,
    canStart: true,
  },
  {
    key: 'EXPIRED',
    display: 'EXPIRED',
    color: 'black',
    tip: 'This workspace has expired. You will need to replace this workspace to ensure you have the latest security updates.',
    spinner: false,
    canTerminate: true,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
  {
    key: 'UNKNOWN',
    display: 'ERROR: CONTACT RACE!',
    color: 'blue',
    tip: 'Something went wrong. This requires further investigation by the RACE team.',
    spinner: false,
    canTerminate: true,
    canConnect: false,
    canStop: false,
    canStart: false,
  },
];

// ==================================================================
// ScEnvironment
// Added root volume for custom storage
// ==================================================================
const ScEnvironment = types
  .model('ScEnvironment', {
    id: types.identifier,
    rev: types.maybe(types.number),
    status: '',
    description: '',
    envTypeConfigId: '',
    enableHyperthreading: types.optional(types.boolean, false),
    name: '',
    rootVolume: '',
    projectId: '',
    envTypeId: '',
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    expiresAt: '',
    error: types.maybeNull(types.string),
    connections: types.frozen([]),
    hasConnections: false,
    studyIds: types.frozen([]),
    cidr: types.frozen([]),
    outputs: types.frozen([]),
    isAppStreamConfigured: types.optional(types.boolean, false),
    terminationLocked: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setScEnvironment(rawEnvironment) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      const raw = { ...rawEnvironment, connections: self.connections || [] };
      if (_.isString(raw.cidr) && _.isArray(self.cidr)) raw.cidr = self.cidr;
      applySnapshot(self, raw);
    },
    setStatus(status) {
      self.status = status;
    },
    setConnections(connections) {
      self.connections = connections;
    },
  }))

  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // State is a generalization of the status name. With the state object, we can provide more
    // information about the state and what can be done/displayed
    get state() {
      // We need to clone the entry so that we don't impact the existing states object
      const entry = _.cloneDeep(_.find(states, ['key', self.status]) || _.find(states, ['key', 'UNKNOWN']));

      // The canConnect value is also determined by looking at the existing state requirement and
      // if we have any connections
      entry.canConnect = entry.canConnect && self.hasConnections;

      return entry;
    },

    getConnections(filterFn = () => true) {
      return _.filter(self.connections, filterFn);
    },
  }));

export { ScEnvironment };
