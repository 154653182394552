/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import { createForm } from '../../helpers/form';

const getFields = ({ projectIdOptions, cidr }) => {
  const fields = {
    name: {
      label: 'Workspace Name',
      placeholder: 'Type a name for this research workspace',
      extra: {
        explain:
          'The workspace name can contain only alphanumeric characters (case sensitive) and hyphens. It must start with an alphabetic character and cannot be longer than 128 characters.',
      },
      rules: 'required|string|between:3,128|regex:/^[A-Za-z][A-Za-z0-9-]+$/',
    },
    description: {
      label: 'Description',
      placeholder: 'The description of this research workspace',
      rules: 'required|string|between:1,2048',
    },
    projectId: {
      label: 'Project ID',
      placeholder: 'The project ID to associate with this research workspace',
      rules: ['required', 'string', 'min:1', 'max:100'],
      extra: {
        options: projectIdOptions,
        explain: 'The project ID you wish to charge usage to.',
      },
    },
    rootVolume: {
      label: 'C Drive/Root Volume Size (GB)',
      placeholder: 'Storage size in GB to attach for this instance',
      extra: {
        explain: 'Enter your desired storage size. (Blank Image: 32-2048GB, GPU Image: 64-2048GB, Custom Image: 96-2048GB).',
      },
      value: '96',
      rules: ['required', 'integer', 'min:32', 'max:2048'],
    },
    envTypeConfigId: {
      label: 'Configuration',
      placeholder: 'The configuration for the research workspace',
      extra: {
        explain: 'Select your hardware configuration. Looking for something not listed? Contact us at race@rmit.edu.au.',
      },
      rules: 'required',
    },
    enableHyperthreading: {
      label: 'Enable Hyperthreading',
      extra: {
        explain: 'Disable this to turn off hyperthreading. (If disabled, this your half your number of vCPUs, where 1 vCPU will equal 1 physical core)',
      },
      value: true,
      rules: ['boolean', 'required'],
    },
  };

  if (!_.isUndefined(cidr)) {
    fields.cidr = {
      label: 'Restricted CIDR',
      extra: {
        explain: `This research workspace will only be reachable from this CIDR (IP address range). The provided default is the CIDR that restricts to your current IP address. You can also change the IP address after creating the research workspace.`,
      },
      placeholder: 'The CIDR range to restrict research workspace access to',
      rules: 'required|cidr',
      value: cidr,
    };
  }

  return fields;
};

function getCreateInternalEnvForm(...args) {
  return createForm(getFields(...args));
}

// eslint-disable-next-line import/prefer-default-export
export { getCreateInternalEnvForm };
